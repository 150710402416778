import React from "react"
import ImagesTwoColumns from "../slices/images-two-columns"
import ImagesThreeColumns from "../slices/images-three-columns"
import ThreeItemsGrid from "../slices/three-items-grid"
import * as Styled from "./styled"

const Slices = props => {

const allSlices = props.input && props.input.map(slice => {

  switch (slice.slice_type) {

    case "two_columns":
      return <ImagesTwoColumns input={ slice } />

    case "three_columns":
      return <ImagesThreeColumns input={ slice } />

    case "three_items":
      return <ThreeItemsGrid input={ slice } />

    default:
      return null

  }
})

  return (
    <Styled.SliceContainer>
      { allSlices }
    </Styled.SliceContainer>
  )
}

export default Slices