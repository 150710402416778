import styled from "styled-components"
import themeSettings from "../../../styles/theme"

export const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: ${themeSettings.margins.minimal};
  margin-bottom: ${themeSettings.margins.page};
  padding-bottom: ${ props => props.descriptionVisibility === "hide" ? 0 : themeSettings.margins.page };

  @media screen and (max-width: 414px) {
    margin-bottom: calc(${themeSettings.mobileMargins.page} * 1.6);
    padding-bottom: ${ props => props.descriptionVisibility === "hide" ? 0 : themeSettings.mobileMargins.page };
  }

  & > figure {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    grid-column: span 2;

    @media screen and (max-width: 414px) {
      grid-column: span 6;
    }

    &:nth-child(1),
    &:nth-child(2) {
      @media screen and (max-width: 414px) {
        padding-bottom: calc(${themeSettings.mobileMargins.page} * 2.8);
      }
    }

    & > h3 {
      margin-top: 4px;
    }

    & > picture {
      width: 100%;
      height: 100%;
      
      & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        vertical-align: bottom;
      }
    }

  }
`

export const Headline = styled.h3`
  font-size: ${themeSettings.fontSizes.xxsmall};
  padding-top: 1px;
  display: ${ props => props.descriptionVisibility === "hide" ? "none" : "block" };
`