import React from "react"
import Styled from "./styled"

const ImagesTwoColumns = props => {

  const inputData = props.input.primary

  return (
    <Styled.Container
      imageSize={ inputData.image_sizes }
      descriptionVisibility={ inputData.image_description_visibility }
    >

      <figure>
        <picture>
          <source media="(min-width: 1281px)"
            srcSet={ inputData.image_one.large.url }
          />
          <source media="(max-width: 1280px)"
            srcSet={ inputData.image_one.medium.url }
          />
          <img
            src={ inputData.image_one.large.url }
            alt={ inputData.image_one.alt }
          />
        </picture>
        <Styled.Headline
          descriptionVisibility={ inputData.image_description_visibility }
        >
          { inputData.image_one_description.text }
        </Styled.Headline>
      </figure>

      <figure>
        <picture>
          <source media="(min-width: 1281px)"
            srcSet={ inputData.image_two.large.url }
          />
          <source media="(max-width: 1280px)"
            srcSet={ inputData.image_two.medium.url }
          />
          <img
            src={ inputData.image_two.large.url }
            alt={ inputData.image_two.alt }
          />
        </picture>
        <Styled.Headline
          descriptionVisibility={ inputData.image_description_visibility }
        >
          { inputData.image_two_description.text }
        </Styled.Headline>
      </figure>

    </Styled.Container>
  )
}

export default ImagesTwoColumns