import React from "react"
import * as Styled from "./styled"

const ThreeItemsGrid = props => {

  const inputData = props.input.primary

  return (
    <Styled.Container
      largeImagePosition={ inputData.large_image_position }
      imageWidths={ inputData.image_widths }
      smallImageSizes={ inputData.small_image_sizes }
      descriptionVisibility={ inputData.image_description_visibility }
    >

      <figure>
        <picture>
          <source
            media="(min-width: 1281px)"
            srcSet={ inputData.image_small_top.large.url }
          />
          <source
            media="(max-width: 1280px)"
            srcSet={ inputData.image_small_top.medium.url }
          />
          <source
            media="(max-width: 1024px)"
            srcSet={ inputData.image_small_top.small.url }
          />
          <img
            src={ inputData.image_small_top.large.url }
            alt={ `${inputData.image_small_top.alt} Studio Fabio Biesel` }
          />
        </picture>
        <Styled.Headline
          descriptionVisibility={ inputData.image_description_visibility }
        >
          { inputData.image_small_top_description.text }
        </Styled.Headline>
      </figure>

      <figure>
        <picture>
          <source
            media="(min-width: 1281px)"
            srcSet={ inputData.image_small_bottom.large.url }
          />
          <source
            media="(max-width: 1280px)"
            srcSet={ inputData.image_small_bottom.medium.url }
          />
          <source
            media="(max-width: 1024px)"
            srcSet={ inputData.image_small_bottom.small.url }
          />
          <img
            src={ inputData.image_small_bottom.large.url }
            alt={ `${inputData.image_small_bottom.alt} Studio Fabio Biesel` }
          />
        </picture>
        <Styled.Headline
          descriptionVisibility={ inputData.image_description_visibility }
        >
          { inputData.image_small_bottom_description.text }
        </Styled.Headline>
      </figure>

      <figure>
        <picture>
          <source
            media="(min-width: 1281px)"
            srcSet={ inputData.image_large_full_height.large.url }
          />
          <source
            media="(max-width: 1280px)"
            srcSet={ inputData.image_large_full_height.medium.url }
          />
          <source
            media="(max-width: 1024px)"
            srcSet={ inputData.image_large_full_height.small.url }
          />
          <img
            src={ inputData.image_large_full_height.large.url }
            alt={ `${inputData.image_large_full_height.alt} Studio Fabio Biesel` }
          />
        </picture>
        <Styled.Headline
          descriptionVisibility={ inputData.image_description_visibility }
        >
          { inputData.image_large_full_height_description.text }
        </Styled.Headline>
      </figure>

    </Styled.Container>
  )
}

export default ThreeItemsGrid