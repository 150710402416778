import React from "react"
import { ThemeProvider } from "styled-components"
import LayoutDefault from "../layouts/default"
import LabBody from "../components/lab-body"
import Footer from "../components/footer"

const LabPage = props => {

  return (
    <ThemeProvider theme={{ mode: "dark" }}>
      <LayoutDefault>
        <LabBody />
        <Footer />
      </LayoutDefault>
    </ThemeProvider>
  )
}

export default LabPage