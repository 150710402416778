import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Slices from "./slices"
import * as Styled from "./styled"

const LabBody = () => {

  const data = useStaticQuery(graphql`
  query LabQuery {
    prismicLab {
      data {

        introduction_paragraph {
          html
        }

        body {

          ... on PrismicLabBodyTwoColumns {
            id
            slice_type
            primary {
              image_sizes
              image_description_visibility
              image_one {
                alt
                large {
                  url
                }
                medium {
                  url
                }
              }
              image_one_description {
                text
              }
              image_two {
                large {
                  url
                }
                medium {
                  url
                }
              }
              image_two_description {
                text
              }
            }
          }

          # ... on PrismicLabBodyThreeColumns {
          #   id
          #   slice_type
          #   primary {
          #     image_description_visibility
          #     image_one {
          #       alt
          #       large {
          #         url
          #       }
          #       medium {
          #         url
          #       }
          #       small {
          #         url
          #       }
          #     }
          #     image_one_description {
          #       text
          #     }
          #     image_two {
          #       large {
          #         url
          #       }
          #       medium {
          #         url
          #       }
          #       small {
          #         url
          #       }
          #     }
          #     image_two_description {
          #       text
          #     }
          #     image_three {
          #       large {
          #         url
          #       }
          #       medium {
          #         url
          #       }
          #       small {
          #         url
          #       }
          #     }
          #     image_three_description {
          #       text
          #     }

          #   }
          # }

          ... on PrismicLabBodyThreeItems {
            id
            slice_type
            primary {
              large_image_position
              image_widths
              small_image_sizes
              image_description_visibility
              image_small_top {
                alt
                large {
                  url
                }
                medium {
                  url
                }
                small {
                  url
                }
              }
              image_small_top_description {
                text
              }
              image_small_bottom {
                alt
                large {
                  url
                }
                medium {
                  url
                }
                small {
                  url
                }
              }
              image_small_bottom_description {
                text
              }
              image_large_full_height {
                alt
                large {
                  url
                }
                medium {
                  url
                }
                small {
                  url
                }
              }
              image_large_full_height_description {
                text
              }
            }
          }

        }
      }
    }
  }
`)

const content = data.prismicLab.data.body

  return (
    <Styled.Section>
      <div dangerouslySetInnerHTML={{ __html: data.prismicLab.data.introduction_paragraph.html }} />
      <Slices input={ content } />
    </Styled.Section>
  )
}

export default LabBody