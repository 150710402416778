import styled from "styled-components"
import themeSettings from "../../styles/theme"

export const Section = styled.section`
  box-sizing: border-box;
  width: 100%;
  margin: calc(${themeSettings.navHeight}px * 1.1) 0 calc(${themeSettings.margins.large} * 2) 0;
  padding: 0 ${themeSettings.margins.page};
  color: ${themeSettings.mainColor};

  @media screen and (max-width: 414px) {
    margin: calc(${themeSettings.mobileNavHeight}px * 1.1) 0 calc(${themeSettings.margins.large} * 2) 0;
    padding: 0 ${themeSettings.mobileMargins.page};
  }
`

export const SliceContainer = styled.div`
  margin-top: calc(${themeSettings.margins.page} * 1);
`