import React from "react"
import * as Styled from "./styled"

const ImagesThreeColumns = props => {

  const inputData = props.input.primary

  return (
    <Styled.Container
      descriptionVisibility={ inputData.image_description_visibility }
    >

      <figure>
        <picture>
          <source
            media="(min-width: 1441px)"
            srcSet={ inputData.image_one.large.url }
          />
          <source
            media="(max-width: 1440px)"
            srcSet={ inputData.image_one.medium.url }
          />
          <source
            media="(max-width: 414px)"
            srcSet={ inputData.image_one.small.url }
          />
          <img
            src={ inputData.image_one.large.url }
            alt={ inputData.image_one.alt }
          />
        </picture>
        <Styled.Headline
          descriptionVisibility={ inputData.image_description_visibility }
        >
          { inputData.image_one_description.text }
        </Styled.Headline>
      </figure>

      <figure>
        <picture>
          <source
            media="(min-width: 1441px)"
            srcSet={ inputData.image_two.large.url }
          />
          <source
            media="(max-width: 1440px)"
            srcSet={ inputData.image_two.medium.url }
          />
          <source
            media="(max-width: 414px)"
            srcSet={ inputData.image_two.small.url }
          />
          <img
            src={ inputData.image_two.large.url }
            alt={ inputData.image_two.alt }
          />
        </picture>
        <Styled.Headline
          descriptionVisibility={ inputData.image_description_visibility }
        >
          { inputData.image_two_description.text }
        </Styled.Headline>
      </figure>
      
      <figure>
        <picture>
          <source
            media="(min-width: 1441px)"
            srcSet={ inputData.image_three.large.url }
          />
          <source
            media="(max-width: 1440px)"
            srcSet={ inputData.image_three.medium.url }
          />
          <source
            media="(max-width: 414px)"
            srcSet={ inputData.image_three.small.url }
          />
          <img
            src={ inputData.image_three.large.url }
            alt={ inputData.image_one.alt }
          />
        </picture>
        <Styled.Headline
          descriptionVisibility={ inputData.image_description_visibility }
        >
          { inputData.image_three_description.text }
        </Styled.Headline>
      </figure>

    </Styled.Container>
  )
}

export default ImagesThreeColumns