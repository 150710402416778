import styled from "styled-components"
import themeSettings from "../../../styles/theme"

const oneThird = "span 2"
const twoThirds = "span 4"
const half = "span 3"

const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: ${themeSettings.margins.minimal};
  margin: ${themeSettings.margins.page} 0;

  @media screen and (max-width: 414px) {
    margin: ${themeSettings.mobileMargins.page} 0;
  }

  & > figure {
    box-sizing: border-box;
    width: 100%;
    grid-column: ${props => 
      props.imageSize === "breit-schmal" ? twoThirds
      : props.imageSize === "schmal-breit" ? oneThird
      : props.imageSize === "halbe-halbe" ? half
      : half
    };

    @media screen and (max-width: 414px) {
      grid-column: span 6;
    }

    &:first-child {
      @media screen and (max-width: 414px) {
        margin-bottom: calc(${themeSettings.margins.page} * 0.6);
      }
    }

    & > picture {
      &:nth-child(1) {

      & > img {
        width: 100%;
        height: ${props => 
          props.imageSize === "breit-schmal" ? "auto"
          : props.imageSize === "schmal-breit" ? "100%"
          : props.imageSize === "halbe-halbe" ? "auto"
          : "auto"
        };
        object-fit: ${props => 
          props.imageSize === "breit-schmal" ? "contain"
          : props.imageSize === "schmal-breit" ? "cover"
          : props.imageSize === "halbe-halbe" ? "contain"
          : "contain"
        };
        vertical-align: bottom;
      }
    }

    &:nth-child(2) {
      width: 100%;
      grid-column: ${props => 
        props.imageSize === "breit-schmal" ? oneThird
        : props.imageSize === "schmal-breit" ? twoThirds
        : props.imageSize === "halbe-halbe" ? half
        : half
      };

      & > img {
        width: 100%;
        height: ${props => 
          props.imageSize === "breit-schmal" ? "100%"
          : props.imageSize === "schmal-breit" ? "auto"
          : props.imageSize === "halbe-halbe" ? "auto"
          : "auto"
        };
        object-fit: ${props => 
          props.imageSize === "breit-schmal" ? "cover"
          : props.imageSize === "schmal-breit" ? "contain"
          : props.imageSize === "halbe-halbe" ? "contain"
          : "contain"
        };
        vertical-align: bottom;
      }

    }
    }

  }
`

const Headline = styled.h3`
  font-size: ${themeSettings.fontSizes.xxsmall};
  padding-top: 4px;
  display: ${ props => props.descriptionVisibility === "hide" ? "none" : "block" };
`

export default {
  Container,
  Headline
}