import styled from "styled-components"
import themeSettings from "../../../styles/theme"

const gridRowGap = "40px"
const marginBottom = "40px"

export const Container = styled.div`
  width: 100%;
  max-height: 90vh;
  margin-top: ${ props => props.descriptionVisibility === "hide" ? themeSettings.margins.page : 0 };
  margin-bottom: ${ props => props.descriptionVisibility === "hide" ? themeSettings.margins.page : marginBottom };
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(6, 1fr);
  grid-column-gap: ${themeSettings.margins.minimal};
  grid-row-gap: ${ props => props.descriptionVisibility === "hide" ? themeSettings.margins.minimal : gridRowGap };

  @media screen and (max-width: 414px) {
    margin-top: ${ props => props.descriptionVisibility === "hide" ? themeSettings.mobileMargins.page : 0 };
    /* margin-bottom: ${ props => props.descriptionVisibility === "hide" ? themeSettings.mobileMargins.page : marginBottom }; */
    margin-bottom: ${ props => props.descriptionVisibility === "hide" ? themeSettings.mobileMargins.page : themeSettings.mobileMargins.page };
    grid-template-rows: initial;
    grid-row-gap: calc(${themeSettings.margins.page} * 0.6);
    max-height: initial;
  }

  & > figure {

    &:nth-child(1) {
      grid-column: ${props => 
        props.largeImagePosition === "Links" && props.imageWidths === "halbe-halbe" ? "4 / span 3"
        : props.largeImagePosition === "Links" && props.imageWidths === "breit-schmal" ? "5 / span 2"
        : props.largeImagePosition === "Links" && props.imageWidths === "schmal-breit" ? "3 / span 4"
        : props.largeImagePosition === "Rechts" && props.imageWidths === "halbe-halbe" ? "1 / span 3"
        : props.largeImagePosition === "Rechts" && props.imageWidths === "breit-schmal" ? "1 / span 4"
        : props.largeImagePosition === "Rechts" && props.imageWidths === "schmal-breit" ? "1 / span 2"
        : "1 / span 3"
      };
      grid-row: ${props =>
        props.smallImageSizes === "halbe-halbe" ? "1 / span 3"
        : props.smallImageSizes === "hoch-niedrig" ? "1 / span 4"
        : props.smallImageSizes === "niedrig-hoch" ? "1 / span 2"
        : "1 / span 3"
      };

      @media screen and (max-width: 414px) {
        grid-column: span 6;
        grid-row: initial;
      }
    }

    &:nth-child(2) {
      grid-column: ${props => 
        props.largeImagePosition === "Links" && props.imageWidths === "halbe-halbe" ? "4 / span 3"
        : props.largeImagePosition === "Links" && props.imageWidths === "breit-schmal" ? "5 / span 2"
        : props.largeImagePosition === "Links" && props.imageWidths === "schmal-breit" ? "3 / span 4"
        : props.largeImagePosition === "Rechts" && props.imageWidths === "halbe-halbe" ? "1 / span 3"
        : props.largeImagePosition === "Rechts" && props.imageWidths === "breit-schmal" ? "1 / span 4"
        : props.largeImagePosition === "Rechts" && props.imageWidths === "schmal-breit" ? "1 / span 2"
        : "1 / span 3"
      };
      grid-row: ${props =>
        props.smallImageSizes === "halbe-halbe" ? "4 / span 3"
        : props.smallImageSizes === "hoch-niedrig" ? "5 / span 2"
        : props.smallImageSizes === "niedrig-hoch" ? "3 / span 4"
        : "4 / span 3"
      };

      @media screen and (max-width: 414px) {
        grid-column: span 6;
        grid-row: initial;
      }
    }

    &:nth-child(3) {
      /* grid-column: ${props => props.largeImagePosition === "Links" ? "1 / span 3" : "4 / span 3"}; */
      grid-column: ${props => 
        props.largeImagePosition === "Links" && props.imageWidths === "halbe-halbe" ? "1 / span 3"
        : props.largeImagePosition === "Links" && props.imageWidths === "breit-schmal" ? "1 / span 4"
        : props.largeImagePosition === "Links" && props.imageWidths === "schmal-breit" ? "1 / span 2"
        : props.largeImagePosition === "Rechts" && props.imageWidths === "halbe-halbe" ? "4 / span 3"
        : props.largeImagePosition === "Rechts" && props.imageWidths === "breit-schmal" ? "5 / span 2"
        : props.largeImagePosition === "Rechts" && props.imageWidths === "schmal-breit" ? "3 / span 4"
        : "1 / span 3"
      };
      grid-row: 1 / span 6;

      @media screen and (max-width: 414px) {
        grid-column: span 6;
        grid-row: initial;
      }
    }

    & > picture {
      width: 100%;
      /* height: 100%; */

      & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        vertical-align: bottom;
      }
    }

  }
`

export const Headline = styled.h3`
  font-size: ${themeSettings.fontSizes.xxsmall};
  padding-top: 4px;
  display: ${ props => props.descriptionVisibility === "hide" ? "none" : "block" };
`